.form-card {
  width: 90% ;
  height: 90% ;
  z-index: 1;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  display: flex;
}

.card-width{
  width: 45vw !important
}

.tenant-details-signup-form {
  width: 80% !important;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-right: 2%;
  justify-self: center;
}

.input-g-title{
  width: 20%;
}

.signup-header {
  font-weight: bold;
  position: relative;
  font-size: 32px;
}

.input-title {
  font-size: 28;
  font-weight: 700;
  text-align: left;
  padding-left: 2px;
  position: relative;
  top: 10px;
}

.housemates-section{
  padding-right: 2%;
}

.housr-button {
  background-color: #109539;
  font-weight: 600;
  border-color: #109539;
  font-size: 14px;
  border-radius: 7px;
}

.btn:active {
  background-color: #086417 !important;
  border-color: #086417 !important;
}

.housr-button:hover {
  background-color: #086417;
  font-weight: 600;
  border-color: #086417;
  border-radius: 7px;
  margin-top: 0vh;
}

.css-13cymwt-control{
    background-color: #fbfcfe !important;
    box-shadow: var(--joy-shadowRing, 0 0 #000), 0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08)) !important;
    border-color: rgb(205, 215, 225) !important;
    border-width: 1px !important;
    border-radius: 6px !important;
}

.housr-button:focus {
  background-color: #086417;
  font-weight: 600;
  border-color: #086417;
  border-radius: 7px;
  margin-top: 0vh;
}

.housr-logo {
  position: relative;
  bottom: 0px;
}

.tile {
  z-index: 10;
  background-color: white;
  width: 30%;
  height: 45%;
  box-shadow: 0px 4px 10px 0px #0000001a;
  border-radius: 20px;
}

.tile-description {
  font-size: 22px;
  color: #393939;
  padding: 3% 5%;
  height: 33%;
}

.right-col {
  width: 50%;
  margin-left: 5%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.review-tile {
  width: 80%;
  box-shadow: 0px 4px 10px 0px #0000001a;
  border-radius: 20px;
  text-align: start;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
  justify-content: space-evenly;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}

.bullet-point {
  position: relative;
  left: 5%;
  top: 2%;
  margin-top: 5%;
}

.background-stripe-right {
  z-index: 1;
  width: 80%;
  height: 40%;
  position: absolute;
  left: 45%;
  top: 35%;
}

.background-stripe-left {
  z-index: 1;
  width: 30%;
  position: absolute;
  right: 70%;
  top: 70%;
}

.tenants-house-image {
  position: relative;
  z-index: 10;

}

.modal-card {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:30vh !important;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;

}

.success-popup {
    top: 0;
    z-index: 10;
    border-radius: 10px;
}

@media screen and (max-width: 1300px) {
  .card-width{
    width: 60vw !important
  }
}

@media screen and (max-width: 1100px) {
  .form-card {
    width: 90% !important;
    height: 90% !important;
    z-index: 1;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    display: flex;
  }

  .signup-form {
    width: 30% !important;
    height: 70% !important;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    left: 8vw;
    top: 0px;
    padding-top: 5%;
    padding-bottom: 2%;
    justify-self: center;
  }

  .house-image {
    position: relative;
    z-index: 10;
    right: 10%;
    top: 1%;
  }

  .review-tile {
    width: 90%;
    box-shadow: 0px 4px 10px 0px #0000001a;
    border-radius: 20px;
    text-align: start;
    padding-left: 5%;
    margin: 4% 0;
    padding-right: 5%;
    padding-top: 1%;
    justify-content: space-evenly;
  }

  .right-col {
    width: 50%;
    position: relative;
    top: 20%;
  }

  .tile {
    z-index: 10;
    right: 10%;
    position: relative;
    background-color: white;
    width: 70%;
    height: 45%;
    box-shadow: 0px 4px 10px 0px #0000001a;
    border-radius: 20px;
  }
}

@media screen and (max-width: 1300px) {
  .background-stripe-right {
    z-index: 0;
  }

  .signup-form {
    width: 30% !important;
    height: 70% !important;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    left: 8vw;
    padding-top: 5%;
    padding-bottom: 2%;
    justify-self: center;
  }

  .house-image {
    position: relative;
    z-index: 10;
    right: 10%;
    top: 1%;
  }

  .right-col {
    width: 50%;
    position: relative;
    top: 0;
  }

  .tile {
    z-index: 10;
    right: 10%;
    position: relative;
    background-color: white;
    width: 85%;
    height: 45%;
    box-shadow: 0px 4px 10px 0px #0000001a;
    border-radius: 20px;
  }

  .tile-description {
    font-size: 18px;
    color: #393939;
    padding: 3% 5%;
    height: 33%;
  }
}

@media screen and (max-width: 900px) {
  .tenant-details-signup-form {
    width: 94% !important;
    margin-top: 2vh;
    height: fit-content;
  }

  .signup-header {
    font-size: 24px;
  }

  .form-card {
    width: 90% !important;
    height: 90% !important;
    z-index: 1;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    display: block;
    top:0px;
    position: relative;
    overflow-y: scroll;
  }

  .right-col {
    width: 95%;
    margin: 0 auto;
    position: relative;
  }

  .house-image {
    position: relative;
    z-index: 10;
    right: auto !important;
    top: 5%;
    z-index: 9;
  }

  .success-popup {
    left: 30%;
  }
}

@media screen and (max-width: 500px) {
  .signup-form {
    width: 80% !important;
    margin-top: 0vh;
  }

  .form-card {
    width: 90% !important;
    height: 90% !important;
    z-index: 1;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    display: block;
    position: relative;
    overflow-y: scroll;
    top: 0vh;
  }

  .card-width{
    width: 100% !important
  }

  .input-g-title{
    width: 20%;
    font-size: x-small;
  }

  .tenants-house-image {
    position: relative;
    z-index: 10;
    width: 80%;
    z-index: 9;
  }

  .inner-house-image {
    width: 100%;
  }
}

@media screen and (max-width: 376px) and (max-height: 670px) {
    .signup-form {
      width: 80% !important;
      margin-top: 0vh;
    }
    
    .form-card {
      width: 90% !important;
      height: 90% !important;
      z-index: 1;
      background-color: white;
      border-radius: 1rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      display: block;
      position: relative;
      overflow-y: scroll;
      top: 0vh;
    }
  
    .house-image {
      position: relative;
      z-index: 10;
      right: 5% !important;
      top: 5%;
      z-index: 9;
    }
  }


.form-card {
  width: 90% ;
  height: 90% ;
  z-index: 1;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  display: flex;
}

.PhoneInputInput {
  border: 1px solid #00000022;
  padding: .5em;
  background-color: #fbfcfe;
  box-shadow: var(--joy-shadowRing, 0 0 #000), 0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08));
  border-color: rgb(205, 215, 225);
  border-width: 1px;
  border-radius: 6px;
}

.rw-widget-input{
  background-color: #fbfcfe;
}

.rw-number-picker-spinners{
  background-color: #fbfcfe;
}

.name-boxes{
    justify-content: space-between !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
}

.single-name-box{
    width: 48% !important;
}

.error-alert {
  width: 100%;
  z-index: 100;
}

.lead-tenant-right-col {
  width: 60%;
  position: relative;
  left: 12.5%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.lead-tenant-signup-form {
  width: 70% !important;
  height: fit-content;
  flex-direction: column;
  display: flex;
  position: relative;
  justify-self: center;
}

.lead-tenant-signup-header {
  font-weight: bold;
  position: relative;
  font-size: 32px;
  justify-content: center !important;
  display: flex !important;
}

.input-title {
  font-size: 28;
  font-weight: 700;
  text-align: left;
  padding-left: 2px;
  position: relative;
  top: 10px;
}

.get-quote-button {
  background-color: #109539;
  font-weight: 600;
  border-color: #109539;
  border-radius: 5px;
  margin-top: 4vh;
  font-size: 14px;
}

.get-quote-button:hover {
  background-color: #086417;
  border-color: #086417;
}

.get-quote-button:focus {
  background-color: #086417;
  border-color: #086417;
}

.housr-logo {
  position: relative;
  top: 0.5vh;
}

.tile-description {
  font-size: 22px;
  color: #393939;
  padding: 3% 5%;
  height: 33%;
  text-align: start;
}

.bullet-point {
  position: relative;
  left: 5%;
  top: 2%;
  margin-top: 5%;
}

.background-stripe-right {
  z-index: 1;
  width: 80%;
  height: 40%;
  position: absolute;
  left: 45%;
  top: 35%;
}

.overlay {
  z-index: 6;
}


.background-stripe-left {
  z-index: 1;
  width: 80%;
  height: 40%;
  position: absolute;
  right: 50%;
  top: 70%;
}

.with-sidebar {
  right: 50% ;
  top: 60%;
}

.house-image {
  position: relative;
  z-index: 10;
  right: 20%;
  z-index: 9;
}

.additional-tenants-modal {
  width: 50vw !important;
  max-width: 100vw !important;
}

@media screen and (max-width: 1100px) {

  .additional-tenants-modal {
    width: 70vw !important;
  }

  .form-card {
    width: 90% !important;
    height: 90% !important;
    z-index: 1;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    display: flex;
  }

  .lead-tenant-right-col {
    width: 50%;
    position: relative;
    left: 10%;
    top: 20%;
  }

  .lead-tenant-signup-form {
    width: 70% !important;
    min-width: 30% !important;
  }
}

@media screen and (max-width: 1300px) {
  .background-stripe-right {
    z-index: 0;
  }

  .lead-tenant-signup-form {
    width: 70% !important;
    min-width: 30% !important;
  }

  .lead-tenant-right-col {
    width: 40%;
    left: 15%;
    top: 0;
  }

  .tile-description {
    font-size: 18px;
    color: #393939;
    padding: 3% 5%;
    height: 33%;
  }
}

@media screen and (max-width: 900px) {

  .additional-tenants-modal {
    width: 90vw !important;
  }

  .lead-tenant-signup-form {
    width: 80% !important;
    margin-top: 5vh;
    padding-top: 5vh;
  }

  .form-card {
    width: 90% !important;
    height: 95% !important;
    padding-bottom: 2%;
    z-index: 1;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    display: block;
    position: relative;
    overflow-y: scroll;
  }

  .lead-tenant-right-col {
    width: 70%;
    left: 0;
    margin: auto;
  }

  .lead-tenant-signup-header {
    justify-content: center !important;
    display: flex !important;
  }

  .house-image {
    position: relative;
    z-index: 10;
    right: auto !important;
    margin-top: 8%;
    z-index: 9;
  }
}

@media screen and (max-width: 768px){
  .additional-tenants-modal {
    width: 70vw !important;
  }

  .tenant-section-modal{
    width: 80% !important
  }

  .name-boxes{
    flex-direction: column !important;
  }

  .single-name-box{
    width: 100% !important;
}

.last-name-margin{
 margin-top:  1rem !important;
}
  .tenant-modal-title {
    font-size: 16px !important
  }
}

@media screen and (max-width: 500px) {
  .additional-tenants-modal {
    width: 85vw !important;
  }

  .lead-tenant-signup-form {
    width: 80% !important;
    margin-top: 0vh;
  }

  .form-card {
    width: 90% !important;
    height: 100% !important;
    z-index: 1;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    display: block;
    position: relative;
    overflow-y: scroll;
    top: 0vh;
    padding: 0 2%;
  }

  .lead-tenant-right-col {
    width: 90%;
    justify-self: center;
  }

  .house-image {
    position: relative;
    z-index: 10;
    right: 5% !important;
    margin-top: 15%;
    z-index: 9;
  }

  .lead-tenant-signup-header{
    font-size: 26px
  }

  .lead-tenant-house-image {
    height: 15vh !important;
  }
}

@media screen and (max-width: 376px) and (max-height: 670px) {
    .lead-tenant-signup-form {
      width: 80% !important;
      margin-top: 0vh;
    }
  
    .form-card {
      width: 90% !important;
      height: 100% !important;
      z-index: 1;
      background-color: white;
      border-radius: 1rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      display: block;
      position: relative;
      overflow-y: scroll;
      top: 0vh;
    }
  
    .lead-tenant-right-col {
      width: 90%;
      justify-self: center;
    }
  
  
    .house-image {
      position: relative;
      z-index: 10;
      right: 5% !important;
      margin-top: 15%;
      z-index: 9;
    }
  }

@media screen and (max-height: 800px){
  .lead-tenant-signup-form{
    padding-right: 3%;
  }
}

@media screen and (max-height: 730px){
  .house-image{
    display: none;
    height: 90% !important;
  }
}

@media screen and (max-height: 800px) {
  .lead-tenant-signup-form {
    top: 5%
  }
}

@media screen and (min-width: 1301px) and (max-width: 1400px) {
  .lead-tenant-right-col{
    width: 40% !important;
    left: 25%
  }
}